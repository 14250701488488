@font-face {
  font-family: HK Grotesk-Bold;
  src: url(../fonts/HKGrotesk-Bold.otf);
}

@font-face {
  font-family: HK Grotesk-Medium;
  src: url(../fonts/HKGrotesk-Medium.otf);
}

@font-face {
  font-family: Inter-Bold;
  src: url(../fonts/Inter-Bold.otf);
}

@font-face {
  font-family: Inter-Medium;
  src: url(../fonts/Inter-Medium.otf);
}

@font-face {
  font-family: Inter-Regular;
  src: url(../fonts/Inter-Regular.otf);
}

/* brand font classes */
.hk-grotesk-bold-font {
  font-family: HK Grotesk-Bold;
}

.hk-grotesk-medium-font {
  font-family: HK Grotesk-Medium;
}

.inter-bold-font {
  font-family: Inter-Bold;
}

.inter-medium-font {
  font-family: Inter-Medium;
}
.inter-regular-font {
  font-family: Inter-Regular;
}
