.styled-container {
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 1%;
  min-width: 100%;
  min-height: 500px;
}

.outer-styled-container {
  background-color: #eeeeee;
}

.middle-styled-container {
  background-color: #dddddd;
}

.inner-styled-container {
  background-color: #ffffff;
}

/* Commonly Used Padding, Margin, Width, Height Related Classes */

.w-100 {
  width: 100%;
}

.mt-2 {
  margin-top: 2rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-1 {
  margin-left: 1rem;
}
.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.p-0 {
  padding: 0;
}
.p-1 {
  padding: 1rem;
}

.save-button,
.close-button {
  width: 107px;
  height: 47px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
}

.savenext-button {
  width: 110px;
  height: 47px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
}

.deleteBtn {
  color: #a3a3a3;
  background: none !important;
  border: none;
  margin-left: 50%;
  margin-top: 20%;
}
/* Common colors classes */
.ant-notification-notice {
  background: #ffff98;
}

.common-text-color {
  color: #201e43 !important;
}

.text-primary {
  color: #f37373;
}
.lightgray-background-color {
  background-color: #eeeeee;
}

.text-white {
  color: white !important;
}

.color-green {
  color: #44ca15;
}
.green-text-color {
  color: #c3dd7a;
}
.color-white {
  color: #ffffff;
}
.darkgrey-color {
  background-color: darkgrey;
}
.darkgrey-font-color {
  color: #717171;
}
.gray-text-color {
  color: #abaaaa;
}
.flexDirectionColumn {
  flex-direction: column;
}
.text-blue {
  color: #201e43;
}

.dark-blue-background {
  background-color: #201e43;
}
/* Common font weights */
.font-weight-400 {
  font-weight: 400;
}
.side-menu-icon-style {
  height: 1.4rem;
  width: 1.4rem;
  margin: 0.5rem;
}

.dashboard-icons-style {
  height: 5rem;
}

/* common class to hide and display an item conditonally */
.show-item-display {
  display: block;
}
.hide-item-display {
  display: none;
}

/* Select dropdown width */
.searchDropdownStyle {
  width: 8rem;
}

/* notes quill container */
.notes-quill-container .ql-container {
  height: 10rem;
  border: none !important;
  margin-bottom: 1rem;
  overflow: auto;
}

/* antd tree select checkbox */

.ant-tree .ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #c3dd7a;
  border-color: #c3dd7a;
}

.ant-tree .ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background: rgba(0, 0, 0, 0.04);
  border-color: #d9d9d9;
}
