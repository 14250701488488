.internal-form-container {
  margin-bottom: 4rem;
  min-width: 100%;
}

.ant-col-24.ant-form-item-label {
  padding: 0px;
}

.react-quill {
  width: 100%;
  min-height: 250px;
}

.button-bottom-margin {
  margin-bottom: 1rem;
}

.padding-internal-form-container {
  padding: 3rem 1rem;
}

.outer-div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.inner-div {
  width: 100%;
  padding-left: 30px;
}

.input-box {
  padding: 5px 0px;
}

.add-button {
  position: absolute;
  top: -130px;
  left: -75px;
}

input {
  width: 94%;
  border: 1px solid #a3a3a3;
  border-radius: 5px;
}

.colored-text {
  color: #f37373;
  font-size: small;
}

.course-display {
  padding: 15px 10px;
  background-color: #eeeeee;
  font-size: 18px;
  font-weight: 600;
}

.section-display {
  font-size: 23px;
  color: #181d31;
  padding: 10px 10px;
  cursor: grab;
}

.lesson-display {
  padding: 10px 35px;
  background-color: #eeeeee;
  font-size: 18px;
}
.topic-display {
  padding: 5px 35px;
  background-color: #ffffff;
  font-size: 14px;
}

.expand-plus-icon {
  height: 30px;
  float: right;
  cursor: pointer;
}

/* preview lms-view styles */
.lms-outer-container {
  padding: 0px !important;
}
.lms-content-viewer {
  background: white;
}
.lms-content-viewer-header {
  height: 4rem;
  align-items: center;
  padding: 1rem 2rem;
}
.lms-content-viewer-header h5 {
  margin: 0px;
}
.lms-content-container {
  height: 30rem;
  display: flex;
  overflow-y: scroll;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.preview-sidemenu {
  height: 36rem;
  overflow-y: scroll;
}
.sider-menu {
  border-left: 1px solid lightgray;
}

.lms-content-viewer-buttons-row {
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.textViewer{
  pointer-events: none;
}
.textViewer .ql-container.ql-snow {
  border: none;
}
.textViewer .tox-tinymce {
  border: none;
}
.textViewer .tox .tox-editor-header {
  display: none;
}
.readOnly-quill .quill .ql-container.ql-snow {
  border: none;
}
