.ant-switch.ant-switch-checked {
  background: #039c15;
}
.tableStyle {
  border: 1px solid rgb(218, 211, 211);
  border-radius: 8px;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  font-size: 1.25em;
  margin-bottom: 10%;
  color: rgb(240, 98, 98);
  display: inline-block;
}
.rti--input{
  height: 12px;
}