.excelUploader .ant-upload-list-item-actions {
  display: block;
}

.excelUploader
  .ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions
  .ant-upload-list-item-action {
  opacity: 1;
}

.excelUploader .ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
  width: 20rem;
  font-size: 1rem;
}
.upload-button {
  margin-top: 2rem;
}
.select-file-button {
  width: 20rem;
}
.excel-upload-icon {
  height: 4rem;
  width: 4rem;
}

.button-display-none {
  display: none;
}
