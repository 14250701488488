.dashboard-card-container {
  width: 19rem;
  height: 15rem;
  border: 1px solid #cccccc;
}
.dashboard-card-row-height {
  height: 100%;
}

.dashboard-card-body-inner-text-row {
  flex-direction: column;
}
.dashboard-card-heading-text-size {
  font-size: 1.5rem;
}
.dashboard-count-text-size {
  font-size: 3.3rem;
}
.separate-count span {
  background-color: #dfecef;
  padding: 0.1rem;
}
