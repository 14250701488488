.bordered-checkbox-container {
  border: 1px solid #a3a3a3;
  border-radius: 5px;
  padding: 0.5rem;
}

.bordered-checkbox-container-scroll {
  border: 1px solid #a3a3a3;
  border-radius: 5px;
  padding: 0.5rem;
  overflow: auto;
  height: 100px;
  scrollbar-width: 1em;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  padding: 12px 16px;
  font-weight: 500;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.85);
  background-color: #bdb8a517;
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  visibility: hidden;
}

.ant-tag {
  margin-right: 2rem;
  background-color: #d8d5d5;
}

.ant-input[disabled],
.ant-checkbox-disabled + span,
.ant-select-selection-item {
  color: #4d4444;
}
