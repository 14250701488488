.student-dashboard-card-body {
  background-color: #f7f7f7;
  width: 20rem;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.2);
  margin: 1rem;
}
.student-card-heading-row {
  height: 6rem;
}

.student-dashboard-card-outer-row {
  height: 12rem;
  flex-direction: column;
}

.student-card-heading-font {
  font-size: 1.5rem;
}

.course-row-divider {
  border-bottom: 1px solid lightgray;
}

.inner-row-space-evenly {
  justify-content: space-evenly;
}

.student-dashboard-card-body:hover {
  box-shadow: 0px 0px 6px 1px #201e43;
}
.student-card-heading-row span:hover {
  color: #201e43;
}

.text-message-font-size {
  font-size: 1.4rem;
}
