.col-left {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
}

.icon p {
  margin: 0;
  margin-left: 10px;
  color: #f37373;
}

.ant-form-item {
  margin-bottom: 0px;
}
.left-inner-row .ant-row {
  margin: 5px 0px;
  width: 100%;
}

.custom-input-width {
  width: 455px;
}

.quill-notes {
  height: 25rem;
}
.PhoneInputInput {
  padding: 0.5rem !important;
}
