.correct-answer {
  color: #ff6e6e;
}

.correct-ans {
  color:green;
  font-weight: 600;
}
.wrong-ans {
  color: red;
  font-weight: 600;

}