.welcome-text {
  text-align: center;
  font-weight: 700;
  font-size: 2.2rem;
}

.body-text {
  margin-top: -40px;
  margin-bottom: 40px;
  text-align: center;
  font-size: 1rem;
}

.forgot-password {
  float: right;
}

.login-form {
  margin-top: 20px;
}

.login-button {
  position: absolute;
  left: 40%;
  bottom: 15%;
}

.toggler{
  width: 11rem;
  font-weight: 600;
}

.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.cursor-dropdown {
  cursor: pointer;
}
