.react-pdf__Page__annotations {
  display: none;
}
.react-pdf__Page__textContent {
  display: none;
}
.pdf-page-border {
  border: 1px solid #eeeeee;
  align-items: center;
  justify-content: center;
}
.pdf-page-number {
  justify-content: center;
}
.pdf-navigation-buttons {
  justify-content: space-around;
}
