:where(.css-dev-only-do-not-override-18ledif).ant-layout .ant-layout-header {
  height: 45px;
  padding-inline: 22px;
  color: #001529;
  line-height: 47px;
  background: #e8e0e0;
}

.footer {
  margin-top: 14%;
  background-color: #eb4962;
}

.text-correct {
  color: rgb(47, 140, 30);
}

.description-content-row {
  margin: 1rem 0rem;
}
.description-content-row span {
  font-size: 0.7rem;
}
.items-row-container {
  border: 1px solid #e5e5e5;
  border-radius: 3px;
}
