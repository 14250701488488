.blackbook-count-row h3 {
  font-size: 3rem;
  color: #201e43;
}

.blackbook-count-row span {
  font-size: 1rem;
  color: #201e43;
  font-family: HK Grotesk-Medium;
}

.vertical-divider {
  height: 6rem;
  background-color: #e5e5e5;
}

.horizontal-divider {
  background-color: #e5e5e5;
}

.blackbook-meta-tags-container span {
  margin: 0.5rem;
}
