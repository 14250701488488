.quill-div {
  min-height: 230px;
}

.form-item-col {
  display: inline-flex;
}
.add-file-form {
  display: inline-flex;
  justify-content: center;
}
.add-file-form {
  display: inline-flex;
  justify-content: center;
}
.add-file-form-input {
  width: 30rem;
  width: 30rem;
}
.ant-upload-list-text {
  display: inline-flex;
}
.upload-form-item {
  width: 90%;
  justify-content: space-between;
}

.ant-upload-list-item-actions {
  display: none;
}
.ant-upload-list-item {
  width: 8rem;
  margin-top: 0 !important;
}
